import React from 'react';
import logo from './evergiven.png';
import './App.css';
import { Button } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});


function openProcureDesk() {
    window.open("https://www.procuredesk.com", "_blank");
}

function cleanUpCsv() {
    window.alert("TODO: ask the user to put a link to the CSV and upload that to the endpoint, then return a CSV")
}

function importAsana() {
    window.alert("TODO: show file picker and send to endpoint then import into Asana")
}

function updateTrackingInfo() {
    window.alert("TODO: update tracking info by checking emails")
}

function openAsana() {
    window.open("https://app.asana.com/0/1161327174211730/list", "_blank");
}

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
        <div className="App">
          <img src={logo} className="App-logo" alt="logo" />
          <main>
            <ol className="tasks">
                <li><Button variant="outlined" onClick={openProcureDesk}>📄 Fetch Orders from ProcureDesk </Button></li>
                <li><Button variant="outlined" onClick={cleanUpCsv}>🧹 Clean Up Order CSV</Button></li>
                <li><Button variant="outlined" onClick={importAsana}>☁️ Import CSV into Asana</Button></li>
                <li><Button variant="outlined" onClick={updateTrackingInfo}>✉️ Update tracking info from CDW</Button></li>
                <li><Button variant="outlined" onClick={openAsana}>🎉 Check Asana</Button></li>
            </ol>
          </main>



{/*           <header className="App-header"> */}
{/*             <p> */}
{/*               Edit <code>src/App.tsx</code> and save to reload. */}
{/*             </p> */}
{/*             <a */}
{/*               className="App-link" */}
{/*               href="https://reactjs.org" */}
{/*               target="_blank" */}
{/*               rel="noopener noreferrer" */}
{/*             > */}
{/*               Learn React */}
{/*             </a> */}
{/*           </header> */}
        </div>
    </ThemeProvider>
  );
}

export default App;
